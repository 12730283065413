// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCzjCnijjQ1F4hNLaDBlH9dT-LkyCgVY4g",
  authDomain: "forumsimulator-7c4f4.firebaseapp.com",
  projectId: "forumsimulator-7c4f4",
  storageBucket: "forumsimulator-7c4f4.appspot.com",
  messagingSenderId: "528117117956",
  appId: "1:528117117956:web:732437e2c9c4b9d2cdf151",
  measurementId: "G-N08CN8DTCF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);

export { app, db, functions };
