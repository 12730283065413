import React, { useState, createContext, useEffect } from "react";

export const TrackingContext = createContext();

export const TrackingProvider = ({ children }) => {
  const [trackingData, setTrackingData] = useState([]);
  const [totals, setTotals] = useState({});
  const [transitions, setTransitions] = useState({});
  const [willNavigate, setWillNavigate] = useState(false);

  // const [totals, setTotals] = useState({'p1':0,'p2':0,'p3':0,'p4':0,'c1':0,'c2':0,'c3':0,'c4':0});

  // Function to add in new tracking data to the state
  const addTrackingData = (newData) => {
    setTrackingData((prev) => [...prev, newData]);
  };

  useEffect(() => {
    //updateTotals(trackingData[trackingData.length])
    updateTransitions();
  }, [trackingData]);

  //function to track transitions of state from one to another
  const updateTransitions = () => {
    //just get the last two entries in trackingData and use them to update transition data
    if (trackingData.length > 1) {
      var transitionID =
        trackingData[trackingData.length - 2].presentationID +
        "-" +
        trackingData[trackingData.length - 1].presentationID;
      setTransitions((transistions) => {
        var transitions2 = { ...transitions };
        if (isNaN(transitions2[transitionID])) {
          //if it doesn't exist yet, just add it and set count at 1
          transitions2[transitionID] = 1;
        } else {
          //increment the transition
          transitions2[transitionID] += 1;
        }

        //track total
        if (isNaN(transitions2["total"])) {
          //if it doesn't exist yet, just add it and set count at 1
          transitions2["total"] = 1;
        } else {
          //increment the transition
          transitions2["total"] += 1;
        }

        return transitions2;
      });
    }
  };

  // Function to track totals
  const updateTotals = (newData) => {
    //first determine if we need to add something new or just add it to the existing structure
    setTotals((totals) => {
      if (isNaN(totals[newData.presentationID])) {
        //if it doesn't exist yet, just add the current time
        totals[newData.presentationID] = newData.timeDiff;
      } else {
        //update the total time
        totals[newData.presentationID] =
          totals[newData.presentationID] + newData.timeDiff;
      }
      return totals;
    });
  };

  return (
    <TrackingContext.Provider
      value={{
        trackingData,
        addTrackingData,
        totals,
        transitions,
        updateTotals,
        updateTransitions,
        willNavigate,
        setWillNavigate,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};
