// import usePageTracker from "hooks/usePageTracker";
// import RankList from "../components/DraggableList/RankList";

// const RankRoute = () => {
//   usePageTracker("rankPage");

//   return (
//     <>
//       <main>
//         <RankList 
//           rankType="..."
//           route="/rank-agreement"
//         />
//       </main>
//     </>
//   );
// };

// export default RankRoute;

import RankList from "../components/DraggableList/RankList";
import usePageTracker
 from "hooks/usePageTracker";
const RankRoute = () => {
  usePageTracker("rankPage");
  return (
    <>
      <main>
        <RankList 
            rankType="Rank according to influence"
            rankInstructions="Please rank the posts below according to how influential they were in shaping how you think about this issue even if you don't agree with their perspectives. More influential/impactful posts should be at the top. To rank, you can change the order by dragging and dropping the posts using your left mouse button."
            route="/evaluation"
        />
      </main>
    </>
  );
};

export default RankRoute;
