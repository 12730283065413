export default function measuresSurvey(remeasure) {
    console.log(remeasure)

    const shuffle = (array) => {
        var currentIndex = array.length, temporaryValue, randomIndex;
      
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
      
          // And swap it with the current element.
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
      
        return array;
    };

    let rows = [
        {
          value: "gov is responsible",
          text: "The government should be responsible for protecting the privacy of citizens’ personal data online",
        },
        {
          value: "gov should enforce laws",
          text: "The government should institute and enforce laws that protect the privacy of citizens’ personal data online",
        },
        {
          value: "gov should mandate corporations",
          text: "The government should mandate how corporations and other organizations store and protect citizens’ personal data online",
        },
        {
          value: "gov should not be involved",
          text: "The government should not be involved in protecting the privacy of citizens’ personal data online",
        },
        {
          value: "gov has no responsibility",
          text: "Ensuring the privacy of online personal data is the responsibility of citizens and private corporations, not the government",
        },
        {
          value: "gov oversight unnecessary",
          text: "Government oversight of online personal data privacy is an unnecessary overreach",
        },
    ]

    shuffle(rows);

    let surveyJson = {
        elements: [
          {
            type: "matrix",
            name: "gov-pre",
            title:
              "Indicate the degree to which you agree or disagree with each of the following statements:",
      
            columns: [
              {
                value: 1,
                text: "Strongly Disagree",
              },
              {
                value: 2,
                text: "Disagree",
              },
              {
                value: 3,
                text: "Neutral",
              },
              {
                value: 4,
                text: "Agree",
              },
              {
                value: 5,
                text: "Strongly Agree",
              },
            ],
            rows: rows
          },
        ],
      };
      
      if (remeasure) {
        surveyJson.elements.push(
            {
                type: "matrix",
                name: "gov-pre",
                title:
                  "Indicate the degree to which you agree or disagree with each of the following statements:",
          
                columns: [
                  {
                    value: 1,
                    text: "Very un-confident",
                  },
                  {
                    value: 2,
                    text: "Not confident",
                  },
                  {
                    value: 3,
                    text: "Neutral",
                  },
                  {
                    value: 4,
                    text: "Confident",
                  },
                  {
                    value: 5,
                    text: "Extremely confident",
                  },
                ],
                rows: [
                  {
                    value: "confident opinion correct",
                    text: "How confident are you that your opinion about data privacy is correct?",
                  },
                ],
              },
              {
                type: "matrix",
                name: "gov-pre",
                title:
                  "Indicate the degree to which you agree or disagree with each of the following statements:",
          
                columns: [
                  {
                    value: 1,
                    text: "Much weaker",
                  },
                  {
                    value: 2,
                    text: "Weaker",
                  },
                  {
                    value: 3,
                    text: "Neutral",
                  },
                  {
                    value: 4,
                    text: "Stronger",
                  },
                  {
                    value: 5,
                    text: "Much stronger",
                  },
                ],
                rows: [
                  {
                    value: "confidence opinion changed",
                    text: "To what degree has your confidence in your opinion changed as a result of what you read?",
                  },
                ],
            },
        )
      } 

    return surveyJson
}