import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import "styles/forms.css";
import PracticeCard from "components/PracticeCard";
import { ForumContext } from "utils/forumContext";
import usePageTracker from "hooks/usePageTracker";
import { comment } from "postcss";
import { trainingComments } from "utils/data";

const trainingSurveyJson = {
  elements: [
    {
      type: "matrix",
      name: "gov-pre",
      title:
        "Indicate the degree to which you agree or disagree with each of the following statements:",

      columns: [
        {
          value: 1,
          text: "Not at all",
        },
        {
          value: 2,
          text: "Slightly",
        },
        {
          value: 3,
          text: "Moderately",
        },
        {
          value: 4,
          text: "Considerably",
        },
        {
          value: 5,
          text: "A great deal",
        },
      ],
      rows: [
        {
          value: "degree community agree",
          text: "To what extent does the community agree with this post?",
        },
        {
          value: "degree community appreciate",
          text: "To what extent does the community appreciate this post?",
        },
      ],
    },
  ],
};



export default function Training() {
  const numPracticeCards = [1, 2, 3, 4];
  const [commentData, setCommentData] = useState(trainingComments);
  const [displayCard, setDisplayCard] = useState(0);
  // const { comments } = useContext(ForumContext);
  const navigate = useNavigate();

  // shuffle(numPracticeCards)

  usePageTracker("trainingPage");

  // If the comment data changes, update the local comment state, setting them all to be closed
  // useEffect(() => {
  //   setCommentData(comments.map((comment) => ({ ...comment, isOpen: false })));
  // }, [comments]);

  // if (commentData.length === 0) {
  //   return (
  //     <section>
  //       <h1>Loading...</h1>
  //     </section>
  //   );
  // }

  const changeDisplayCard = (incrementer) => {
    setDisplayCard((prev) => {
      if (prev + incrementer === numPracticeCards.length) {
        navigate("/instruction");
      } else {
        setDisplayCard(prev + incrementer);
      }
    });
  };

  return (
    <>
      <div className="mx-auto h-full max-w-5xl p-4">
        <h1 className="mb-1 text-3xl font-bold">Training</h1>
        <p className="mx-auto max-w-5xl rounded bg-amber-100 p-4 font-medium text-black">
          Before starting the main task, please review the following example posts and then answer the questions about each.
        </p>
        {numPracticeCards.map((numCard, index) =>
          displayCard === index ? (
            <PracticeCard
              key={index}
              commentType={commentData[index].commentType}
              title={commentData[index].title}
              shortTitle={commentData[index].shortTitle}
              presentationID={commentData[index].presentationID}
              logicalID={commentData[index].logicalID}
              content={commentData[index].content}
              up={commentData[index].up}
              down={commentData[index].down}
              appreciate_up={commentData[index].appreciate_up}
              isOpen={commentData[index].isOpen}
              changeDisplayCard={changeDisplayCard}
              surveyJson={trainingSurveyJson}
              page="training"
            />
          ) : null
        )}
      </div>
    </>
  );
}
