import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import addTrackingData from "firebase-helpers/addTrackingData";
import { TrackingContext } from "utils/trackingContext";
import { ForumContext } from "utils/forumContext";
import { addPageData } from "firebase-helpers/addPageData";

const UploadTrackingData = ({ errorHandler }) => {
  const { trackingData, setWillNavigate } = useContext(TrackingContext);
  const { participantID } = useContext(ForumContext);

  const [uploadState, setUploadState] = useState({
    didUpload: false,
    isUploading: false,
  });
  const navigate = useNavigate();

  return (
    <button
      type="button"
      className="rounded bg-gray-100 px-4 py-2 font-semibold text-gray-800 hover:bg-gray-200"
      disabled={uploadState.isUploading}
      onClick={() => {
        if (trackingData.length === 0) {
          errorHandler();
          return;
        }

        setWillNavigate(true);
        setTimeout(() => {
          navigate("/re-measure");
        }, 10);
      }}
    >
      Continue
    </button>
  );
};

export default UploadTrackingData;
