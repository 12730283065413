import { useEffect, useRef } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import useTiming from "hooks/useTiming";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsDown,
  faThumbsUp,
  faHandshake,
} from "@fortawesome/free-regular-svg-icons";
import { useContext } from "react";
import { TrackingContext } from "utils/trackingContext";

function DropdownComment({
  content,
  up,
  down,
  appreciate_up,
  appreciate_down,
  shortTitle,
  commentType,
  presentationID,
  logicalID,
  orderID,
  isOpen,
  setOpen,
}) {
  // This hook will take care of all of the timing functions - Requires the issue id
  const { mouseOverHandler, mouseOutHandler, startHover } = useTiming(
    presentationID,
    logicalID,
    orderID
  );
  const { willNavigate } = useContext(TrackingContext);

  const contentEl = useRef();

  // Update the mouse out status
  useEffect(() => {
    // When the item closes

    if (startHover !== 0 && !isOpen) {
      mouseOutHandler();
    }
  }, [isOpen]);

  useEffect(() => {
    if (willNavigate && isOpen) {
      mouseOutHandler();
    }
  }, [willNavigate]);

  return (
    <div
      className={`relative w-full overflow-hidden rounded border-2 border-solid ${
        commentType === "pro" ? "border-pro" : "border-con"
      }`}
    >
      <div
        className={`relative z-20 flex w-full items-center justify-between px-6 py-2 text-white ${
          commentType === "pro" ? "bg-pro" : "bg-con"
        }`}
      >
        <h1>
          <span className="font-bold">{shortTitle}</span> ({commentType})
        </h1>
        <div
          className="z-30 rounded bg-white px-4 py-1 text-black"
          onClick={() => {
            if (isOpen) {
              setOpen(false);
            } else {
              setOpen(true);
              mouseOverHandler();
            }
          }}
        >
          {isOpen ? (
            <ChevronUpIcon className="h-4 w-4" />
          ) : (
            <ChevronDownIcon className="h-4 w-4" />
          )}
        </div>
      </div>
      <div
        ref={contentEl}
        className={`z-10 flex cursor-default items-center justify-center px-6 text-lg transition-all duration-300 ease-in-out ${
          isOpen ? `h-auto py-4 opacity-100` : `h-0 p-0 opacity-0`
        }`}
      >
        <p>{content}</p>
      </div>
      <div className="flex-start relative flex max-h-fit border-t-2 border-solid border-t-gray-100 px-6 py-2">
        <div className={`flex-initial flex-col items-center rounded border-2 border-solid ${
          commentType === "pro" ? "border-pro" : "border-con"
          }`}>
          <div className={`px-4 py-1 text-white ${
            commentType === "pro" ? "bg-pro" : "bg-con"
            }`}>
            Agreement</div>
          {/* <div className="flex items-center justify-center space-x-1 py-1"> ---Use this if we add back in up and down votes */}
          <div className="flex items-center justify-center space-x-2 py-5"> 
            {/* <ThumbUpIcon className="w-4 h-4" /> */}
            <FontAwesomeIcon
              icon={faThumbsUp}
              size="lg"
              className={commentType === "pro" ? "text-pro" : "text-con"}
            />
            <span
              className={`justify-center  px-1 text-lg font-bold ${
                commentType === "pro" ? "text-pro" : "text-con"
              }`}
            >
              {up}
            </span>
          </div>
          {/* <div className="flex items-center justify-center space-x-1 py-1">
            <FontAwesomeIcon
              icon={faThumbsDown}
              size="lg"
              className={commentType === "pro" ? "text-pro" : "text-con"}
            />
            <span
              className={`justify-center  px-1 text-lg font-bold ${
                commentType === "pro" ? "text-pro" : "text-con"
              }`}
            >
              {down}
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default DropdownComment;
