import RankList from "../components/DraggableList/RankList";

const RankAppreciationRoute = () => {
  return (
    <>
      <main>
        <RankList 
            rankType="Rank these according to your appreciation of the posts"
            route="/evaluation"
        />
      </main>
    </>
  );
};

export default RankAppreciationRoute;
