import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import "./styles/index.css";
import reportWebVitals from "./reportWebVitals";
import RankRoute from "./routes/rank";
import { TrackingProvider } from "./utils/trackingContext";
import { ForumProvider } from "utils/forumContext";
import IntroPage from "routes/intro";
import PreMeasures from "routes/pre-measures";
import Intermediate from "routes/intermediate";
import ElaborationPage from "routes/elaboration";
import Training from "routes/training";
import Evaluation from "routes/evaluation";
import ReMeasure from "routes/re-measure";
import RankAgreementRoute from "routes/rank-agreement";
import RankAppreciationRoute from "routes/rank-appreciation";
import InstructionPage from "routes/instruction";
import ConsentPage from "routes/consent";

const Layout = () => {
  return (
    <div>
      <main>
        <Outlet />
      </main>
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <ForumProvider>
      <TrackingProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<IntroPage />} />
              <Route path="/elaboration" element={<ElaborationPage />} />
              <Route path="/rank" element={<RankRoute />} />
              <Route path="/pre-measures" element={<PreMeasures />} />
              <Route path="/intermediate" element={<Intermediate />} />
              <Route path="/training" element={<Training />} />
              <Route path="/evaluation" element={<Evaluation />} />
              <Route path="/re-measure" element={<ReMeasure />} />
              <Route path="/rank-agreement" element={<RankAgreementRoute />} />
              <Route path="/rank-appreciation" element={<RankAppreciationRoute />} />
              <Route path="/instruction" element={<InstructionPage /> } />
              <Route path="/consent" element={<ConsentPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </TrackingProvider>
    </ForumProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
