import { Survey, Model } from "survey-react";
import { useCallback, useContext, useState } from "react";

import { ForumContext } from "utils/forumContext";
import Comment from "../components/Comment";
import { addDataWithKey } from "firebase-helpers/addDataWithKey";
import formCss from "styles/formCss";
import { addPageData } from "firebase-helpers/addPageData";

export default function PracticeCard({
  commentType,
  title,
  shortTitle,
  presentationID,
  logicalID,
  content,
  up,
  down,
  appreciate_up,
  changeDisplayCard,
  surveyJson,
  page,
}) {
  const { participantID } = useContext(ForumContext);
  const [isOpen, setIsOpen] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  // Create a new survey model from JSON Data above
  const survey = new Model(surveyJson);
  survey.focusFirstQuestionAutomatic = false;
  survey.completeText = "Continue";

  // Require them to fill out all rows
  var q = survey.getQuestionByName("gov-pre");
  q.isAllRowRequired = true;

  // This is the function that will be called when the survey is complete
  // I will write a function to upload or manage the data on the server that we can then use
  const uploadResults = useCallback(
    async (sender) => {
      setSubmitting(true);

      addPageData(`${logicalID}`, sender.data, participantID, page).then(() => {
        changeDisplayCard(1);
      });
    },
    [participantID, changeDisplayCard]
  );

  // This just adds that callback function to the survey
  survey.onComplete.add(uploadResults);

  return (
    <>
      <section className="mx-auto max-w-5xl space-y-4 pt-4">
        <Comment
          key={title}
          commentType={commentType}
          title={title}
          shortTitle={shortTitle}
          presentationID={presentationID}
          logicalID={logicalID}
          content={content}
          up={up}
          down={down}
          appreciate_up={appreciate_up}
          isOpen={isOpen}
          setOpen={(state) => {
            setIsOpen(state);
          }}
        />
      </section>
      <div className="mx-auto max-w-5xl">
        <Survey model={survey} css={formCss} />
        {submitting && <p className="mt-2 text-right">Saving...</p>}
      </div>
    </>
  );
}
