// import getForumData from "../../utils/data";
import { useContext, useEffect, useState } from "react";

import Comment from "./Comment";
import { ForumContext } from "utils/forumContext";

const CommentGrid = () => {
  const shuffle = (array) => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };
  const [commentData, setCommentData] = useState([]);
  let { comments } = useContext(ForumContext);

  // shuffle(comments);

  // If the comment data changes, update the local comment state, setting them all to be closed
  useEffect(() => {
    setCommentData(comments.map((comment) => ({ ...comment, isOpen: false })));
  }, [comments]);

  if (commentData.length === 0) {
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }
  return (
    <section className="mx-auto max-w-5xl space-y-4 pt-4">
      {commentData.map((comment, index) => (
        <Comment
          key={comment.logicalID}
          commentType={comment.commentType}
          title={comment.title}
          shortTitle={comment.shortTitle}
          presentationID={comment.presentationID}
          orderID={comment.orderID}
          logicalID={comment.logicalID}
          content={comment.content}
          up={comment.up}
          down={comment.down}
          appreciate_up={comment.appreciate_up}
          isOpen={comment.isOpen}
          setOpen={(state) => {
            setCommentData((prev) => {
              // Copy new data while setting all open states to false
              // There could be a better way to do this
              let newForumData = prev.map((comment) => ({
                ...comment,
                isOpen: false,
              }));

              // Set open state of curent dropdown comment
              newForumData[index].isOpen = state;

              return newForumData;
            });
          }}
        />
      ))}
    </section>
  );
};

export default CommentGrid;
