import { Link } from "react-router-dom";

export default function NavBar({ nextPage, instructions }) {
  return (
    <nav className="mt-6">
      <div className="mx-auto flex w-full max-w-6xl items-center justify-between">
        {instructions ?? <div></div>}
        <Link
          className="rounded bg-gray-100 px-4 py-2 font-semibold text-gray-800 hover:bg-gray-200"
          to={nextPage}
        >
          Continue
        </Link>
      </div>
    </nav>
  );
}
