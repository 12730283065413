import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase";

export default async function getCommentData(participantID) {
  const querySnapshot = await getDocs(
    collection(db, "participants", participantID, "comments")
  );

  const forumData = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  // Go through each forum data forumDataId and get the data from firestore
  const comments = await Promise.all(
    forumData.map(async (forumComment) => {
      const querySnapshot = await getDoc(
        doc(db, "forumData", forumComment.forumDataId)
      );

      const data = querySnapshot.data();

      return {
        ...forumComment,
        commentType: data.commentType,
        content: data.content,
        logicalID: data.logicalID,
        shortTitle: data.shortTitle,
      };
    })
  );

  return comments;
}
