import RankList from "../components/DraggableList/RankList";

const RankAgreementRoute = () => {
  return (
    <>
      <main>
        <RankList 
            rankType="Rank these according to your agreement with the posts"
            route="/rank-appreciation"
        />
      </main>
    </>
  );
};

export default RankAgreementRoute;
