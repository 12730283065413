var formCss = {
  matrix: {
    root: "table",
  },
  navigationButton: "button",
  question: {
    header: "hidden",
    formGroup: "bg-blue-500",
  },
  error: {
    root: "text-red-600 mb-2 text-lg font-semibold text-center",
  },
};

export default formCss;
