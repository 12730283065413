import { doc, setDoc } from "firebase/firestore";
import { db } from "./firebase";

export const addPageData = async (key, data, participantID, pageID) => {
  const results = JSON.stringify(data);

  let updateData = {};
  updateData[key] = results;

  await setDoc(
    doc(db, "participants", participantID, "pages", pageID),
    updateData,
    {
      merge: true,
    }
  )
    .catch((e) => {
      console.log("Error from firebase", e);
    })
    .then(() => {
      return "Done";
    });
};
