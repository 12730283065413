import NavBar from "components/Layout/NavBar";
import usePageTracker from "hooks/usePageTracker";

export default function ConsentPage() {
  usePageTracker("consentPage");

  return (
    <>
      <div className="mx-auto h-full max-w-2xl py-4">
        <h1 className={`mb-1 text-3xl font-bold`}>Online Consent to Participate in Research</h1>
        <p className="mb-2">
        Would you like to be involved in research at the University of Oklahoma?
        <br /><br />
        I am Matthew Jensen from the Management Information Systems Department, 
        and I invite you to participate in my research project entitled 
        Understating the Personal Privacy Debate. 
        This research is being conducted at the University of Oklahoma. 
        You were invited to participate based upon your membership with the Prolific service.&nbsp;
        <i>You must be at least 18 years of age to participate in this study, a resident of the United States, 
          and have used online discussion boards in the past. </i> 
          Please note that the survey has several questions that require an answer in order to advance, 
          and that you must pass attention checks in order to be compensated.
        <br /><br />
        Please read this document and contact me to ask any questions that you may have BEFORE agreeing to take part in my research.
        </p>
        <br/>
        <h3 className={`mb-1 text-l font-bold`}>What is the purpose of this research?</h3>
        <p>
        The purpose of this research is to better understand how people form preferences regarding regulations over personal privacy. 
        </p>
        <br/>
        <h3 className={`mb-1 text-l font-bold`}>How many participants will be in this research?</h3>
        <p>
        About 400 people will take part in this research.
        </p>
        <br/>
        <h3 className={`mb-1 text-l font-bold`}>What will I be asked to do?</h3>
        <p>
        If you agree to be in this research, you will complete a single survey. For scientific purposes, you may be 
        misled about the nature of the study or some of the study materials.
        </p>
        <br/>
        <h3 className={`mb-1 text-l font-bold`}>How long will this take?</h3>
        <p>
        Your participation will take approximately <b>30 minutes</b> for this survey.
        </p>
        <br/>
        <h3 className={`mb-1 text-l font-bold`}>What are the risks and/or benefits if I participate?</h3>
        <p>
        There are no risks and no benefits from being in this research.
        </p>
        <br/>
        <h3 className={`mb-1 text-l font-bold`}>Will I be compensated for participating?</h3>
        <p>
        You will be awarded in accordance with your agreement with Prolific; 
        however, you must meet the qualifications and complete the study to be compensated. Additionally, 
        you will need to pass attention checks in order to be compensated.
        </p>
        <br/>
        <h3 className={`mb-1 text-l font-bold`}>Who will see my information?</h3>
        <p>
        In research reports, there will be no information that will make it possible to identify you. 
        Research records will be stored securely and only approved researchers and the OU Institutional 
        Review Board will have access to the records.
        <br/><br/>
        Data are collected via an online survey system that has its own privacy and security policies for 
        keeping your information confidential. Please note no assurance can be made as to the use of the 
        data you provide for purposes other than this research.
        </p>
        <br/>
        <h3 className={`mb-1 text-l font-bold`}>What will happen to my data in the future?</h3>
        <p>
        We will not share your data or use it in future research projects.
        </p>
        <br/>
        <h3 className={`mb-1 text-l font-bold`}>Do I have to participate?</h3>
        <p>
        No. If you do not participate, you will not be penalized or lose benefits or services unrelated to the research. 
        If you decide to participate, you will have to answer all of the questions in the survey and cannot skip any questions. 
        Response is required for each item in order to advance through the study and receive credit.
        </p>
        <br/>
        <h3 className={`mb-1 text-l font-bold`}>Who do I contact with questions, concerns or complaints?</h3>
        <p>
        If you have questions, concerns or complaints about the research or have experienced a research-related injury, contact Matthew Jensen at mjensen@ou.edu, (405) 325-3645.
        <br /><br />
        You can also contact the University of Oklahoma – Norman Campus Institutional Review Board (OU-NC IRB) at 405-325-8110 or irb@ou.edu 
        if you have questions about your rights as a research participant, concerns, or complaints about the research and wish to talk to someone other than the researcher(s) 
        or if you cannot reach the researcher(s).
        <br /><br />
        Please print this document for your records. By providing information to the researcher(s), I am agreeing to participate in this research.
        <br /><br />
        This study has been approved by the University of Oklahoma IRB.
        <br/>IRB Number: 14730
        <br/>Approval date: June 13, 2022
        </p>
        <NavBar nextPage="/pre-measures" />
      </div>
    </>
  );
}
