import {
  collection,
  getDocs,
  addDoc,
  writeBatch,
  doc,
} from "firebase/firestore";
import { db } from "./firebase";

const experimentID =  "Agree_1"

export const initData = async () => {
  // Initialize user

  // Get the location
  const location = await fetch("https://geolocation-db.com/json/").then((res) =>
    res.json()
  );

  console.log(location);

  // Add a new document with a generated id.
  const participant = await addDoc(collection(db, "participants"), {
    hasFinished: false,
    zipCode: location?.postal ?? "no zip",
    state: location?.state ?? "no state",
    city: location?.city ?? "no city",
    countryCode: location?.country_code ?? "no country",
    ipAddress: location?.IPv4 ?? "no ip",
    experimentID: experimentID,
  });

  // Grab data and create an array of forum elements
  const querySnapshot = await getDocs(collection(db, "forumData"));
  const forumData = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  const shuffle = (array) => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  // Function to generate random number
  const getRandomNum = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  };

  const highMedian = 150;
  const lowMedian = 20;
  const appreciateHighMedian = 150;
  const appreciateLowMedian = 20;
  const numDeviation = 10;

  let numPro = 1;
  let numCon = 1;
  let up = null;
  let down = null;
  let appreciation = null;
  let proCues = ["HH", "HL", "LH", "LL"];
  let conCues = ["HH", "HL", "LH", "LL"];

  // randomize comment order and cues order
  shuffle(forumData);
  shuffle(proCues);
  shuffle(conCues);

  const cueAssignment = (cue) => {
    if (cue[0] === "H") {
      up = getRandomNum(highMedian - numDeviation, highMedian + numDeviation);
      down = getRandomNum(lowMedian - numDeviation, lowMedian + numDeviation);
    } else {
      up = getRandomNum(lowMedian - numDeviation, lowMedian + numDeviation);
      down = getRandomNum(highMedian - numDeviation, highMedian + numDeviation);
    }
    appreciation =
      cue[1] === "H"
        ? getRandomNum(
            appreciateHighMedian - numDeviation,
            appreciateHighMedian + numDeviation
          )
        : getRandomNum(
            appreciateLowMedian - numDeviation,
            appreciateLowMedian + numDeviation
          );
  };

  // Create comments
  let comments = forumData.map((item, index) => {
    let presentationID = "";

    if (item.commentType === "pro") {
      presentationID = `p${numPro}`;
      numPro++;
      // assign cue values
      cueAssignment(proCues[0]);
      // remove used cue
      proCues.splice(0, 1);
    } else {
      presentationID = `c${numCon}`;
      numCon++;
      // assign cue values
      cueAssignment(conCues[0]);
      // remove used cue
      conCues.splice(0, 1);
    }

    return {
      forumDataId: item.id,
      up: up,
      down: down,
      appreciate_up: appreciation,
      title: "Pro #1",
      presentationID,
      orderID: index+1,
    };
  });

  // Save Comments

  // Get a new write batch
  const batch = writeBatch(db);

  comments.forEach((comment, index) => {
    // Set the value of 'NYC'
    const commentRef = doc(
      db,
      `participants/${participant.id}/comments/${index + 1}`
    );

    batch.set(commentRef, comment);
  });

  // Commit the batch
  await batch.commit();

  // Give back all the data about the comment
  const localComments = comments.map((comment, index) => {
    return {
      ...comment,
      ...forumData[index],
    };
  });

  return {
    user: participant.id,
    comments: localComments,
  };
};
