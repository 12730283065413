import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ForumContext } from "utils/forumContext";
import { addPageData } from "firebase-helpers/addPageData";

const UploadRankButton = ({ data, dbKey, route }) => {
  const { participantID } = useContext(ForumContext);

  const [uploadState, setUploadState] = useState({
    didUpload: false,
    isUploading: false,
  });
  const navigate = useNavigate();

  return (
    <button
      type="button"
      className="rounded bg-gray-100 px-4 py-2 font-semibold text-gray-800 hover:bg-gray-200"
      disabled={uploadState.isUploading}
      onClick={() => {
        setUploadState({
          didUpload: false,
          isUploading: true,
        });
        addPageData(dbKey, data, participantID, "rankPage").then(() => {
          setUploadState({ didUpload: true, isUploading: false });
          navigate(route);
        });
      }}
    >
      Continue
    </button>
  );
};

export default UploadRankButton;
