import getCommentData from "firebase-helpers/getCommentData";
import { initData } from "firebase-helpers/initData";
import React, { useState, createContext, useEffect } from "react";

export const ForumContext = createContext();

export const ForumProvider = ({ children }) => {
  const [participantID, setParticipantID] = useState(null);
  const [comments, setComments] = useState([]);

  // This will be run when the provider is initialized
  useEffect(() => {
    // Create a new participant with comments if one does not already exist
    // Set data and then save it to the provider

    if (sessionStorage.getItem("participantID")) {
      let participantID = sessionStorage.getItem("participantID");

      // Get the comment data from database
      getCommentData(participantID).then((comments) => {
        setParticipantID(participantID);
        setComments(comments);
      });
    } else {
      initData().then((res) => {
        setParticipantID(res.user);
        setComments(res.comments);

        // Save data to the session storage
        sessionStorage.setItem("participantID", res.user);
      });
    }
  }, []);

  return (
    <ForumContext.Provider
      value={{
        comments,
        participantID,
      }}
    >
      {children}
    </ForumContext.Provider>
  );
};
