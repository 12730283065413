import { Survey, Model } from "survey-react";
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ForumContext } from "utils/forumContext";
import "styles/forms.css";
import formCss from "styles/formCss";
import { addPageData } from "firebase-helpers/addPageData";
import usePageTracker from "hooks/usePageTracker";
import measuresSurvey from "components/measuresSurvey";

export default function PreMeasures() {
  const navigate = useNavigate();
  const { participantID } = useContext(ForumContext);
  const [submitting, setSubmitting] = useState(false);

  usePageTracker("preMeasuresPage");

  // Create a new survey model from JSON Data in measuresSurvey component
  const survey = new Model(measuresSurvey(false));
  survey.focusFirstQuestionAutomatic = false;
  survey.completeText = "Continue";

  // Require them to fill out all rows
  var q = survey.getQuestionByName("gov-pre");
  q.isAllRowRequired = true;

  // This is the function that will be called when the survey is complete
  // I will write a function to upload or manage the data on the server that we can then use
  const submitResults = useCallback(
    async (sender) => {
      setSubmitting(true);

      addPageData("surveyData", sender.data, participantID, "preMeasures").then(
        () => {
          navigate("/intermediate");
        }
      );
    },
    [participantID, navigate]
  );

  // This just adds that callback function to the survey
  survey.onComplete.add(submitResults);

  return (
    <>
      <div className="mx-auto h-full max-w-5xl p-4">
        <h1 className="mb-1 text-3xl font-bold">Government Oversight of Data Privacy</h1>
        <p>
          The forum posts in this experiment deal with the government’s
          involvement in the protection of online personal data. Specifically,
          each post is either in favor or against the proposition that: The
          government should oversee the handling of online personal data by
          corporations and other entities to ensure the privacy of this
          information.
        </p>
        <br />
        <p>
          Before proceeding with the experiment, please fill out the following
          survey.
        </p>
      </div>
      <div className="mx-auto h-full max-w-6xl pb-16">
        {submitting ? (
          <div className="flex w-full items-center justify-center">
            <svg
              role="status"
              className="mr-2 h-8 w-8 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        ) : (
          <div className="mx-auto max-w-5xl">
            <Survey model={survey} css={formCss} />
          </div>
        )}
      </div>
    </>
  );
}
