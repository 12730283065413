import { addPageData } from "firebase-helpers/addPageData";
import { useContext, useEffect } from "react";
import { ForumContext } from "utils/forumContext";

export default function usePageTracker(pageName) {
  const { participantID } = useContext(ForumContext);
  const startTime = new Date();

  useEffect(() => {
    return () => {
      if (!participantID) {
        return;
      }

      let stopTime = new Date();

      const pageTrackingData = {
        startTime,
        stopTime,
        duration: stopTime - startTime,
      };

      console.log(pageTrackingData, participantID, pageName);

      addPageData("pageTrackingData", pageTrackingData, participantID, pageName)
        .then((e) => {})
        .catch((e) => console.log(e));
    };
  }, [participantID]);

  return {};
}
