import Comment from "components/Comment";
// import { ForumContext } from "utils/forumContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsDown,
  faThumbsUp,
  faHandshake,
} from "@fortawesome/free-regular-svg-icons";
import { useContext, useEffect, useState } from "react";
import NavBar from "components/Layout/NavBar";
import usePageTracker from "hooks/usePageTracker";
import { trainingComments } from "utils/data";

const IntermediatePage = () => {
  const [comment, setComment] = useState(null);
  const [comment2, setComment2] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  // const { comments } = useContext(forumContext);
  const [comments, setComments] = useState(trainingComments);

  usePageTracker("intermediatePage");

  // If the comment data changes, update the local comment state, setting them all to be closed
  useEffect(() => {
    // setComment(comments[0]);
    setComment(comments[0]);
    // get comment that's opposite (pro/con) of the previous set comment
    setComment2(comments.find(comment => comment.commentType !== comments[0].commentType));
  }, [comments]);

  if (!comment) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="mx-auto h-full max-w-5xl py-4">
        <h1 className={`mb-1 text-3xl font-bold`}>Overview</h1>
        <p className="mb-2">
          During the experiment, you will see eight boxes that display actual posts
          taken from an online forum thread about government oversight of online
          data privacy. Before we get started, we want to give you an overview of what you will see. 
          Two sample posts relating to a different issue are shown here to give you an idea of how the posts will appear:
        </p>
        <section className="mx-auto max-w-5xl space-y-4 pt-4">
          <Comment
            key={comment.title}
            commentType={comment.commentType}
            title={comment.title}
            shortTitle={comment.shortTitle}
            presentationID={comment.presentationID}
            logicalID={comment.logicalID}
            content={comment.content}
            up={comment.up}
            down={comment.down}
            appreciate_up={comment.appreciate_up}
            isOpen={isOpen}
            setOpen={(state) => {
              setIsOpen(state);
            }}
          />
        </section>
        <section className="mx-auto max-w-5xl space-y-4 pt-4">
          <Comment
            key={comment2.title}
            commentType={comment2.commentType}
            title={comment2.title}
            shortTitle={comment2.shortTitle}
            presentationID={comment2.presentationID}
            logicalID={comment2.logicalID}
            content={comment2.content}
            up={comment2.up}
            down={comment2.down}
            appreciate_up={comment2.appreciate_up}
            isOpen={isOpen}
            setOpen={(state) => {
              setIsOpen(state);
            }}
          />
        </section>
        <br />
        <p>Note the following about these posts:</p>
        <ul className="ml-2 mb-2 list-outside list-disc space-y-2">
          <li>
            Each post states an opinion about an issue and offers reasons
            supporting the opinion. In the experiment, those in favor of government oversight are
            outlined in <span className="text-pro"><b>gray</b></span>, and those opposed are outlined in <span className="text-con"><b>red</b></span>.
          </li>
          <li>
            The title of each post is visible in the box. Initially the content of each post will be collapsed. 
            Clicking on the down arrow at the top right of the box will reveal the content of the post and clicking the up
            arrow will then collapse the content.
          </li>
          <li>
            Posts have accompanying icons that show other people’s
            reactions to the post. Specifically, we asked 200 people (100 in
            favor of the proposition and 100 opposed) to read each post and,
            optionally, offer their feedback. Each person had the opportunity to
            indicate:
            <ul className="list-outside">
              <li>
                Whether they <b>agreed</b> with the post’s
                position. Agreement was indicated by clicking the thumbs up icon (<FontAwesomeIcon
                        icon={faThumbsUp}
                        size="lg"
                        className="text-neutral-600"
                      />).
                       {/* and disagreement was shown by clicking the thumbsdown icon (<FontAwesomeIcon
                        icon={faThumbsDown}
                        size="lg"
                        className="text-neutral-600"
                      />). */}
              </li>
            </ul>
          </li>
        </ul>
        <br />
        <NavBar nextPage="/training" />
      </div>
    </>
  );
};

export default IntermediatePage;
