function shuffleArray(array) {
  let curId = array.length;
  // There remain elements to shuffle
  while (0 !== curId) {
    // Pick a remaining element
    let randId = Math.floor(Math.random() * curId);
    curId -= 1;
    // Swap it with the current element.
    let tmp = array[curId];
    array[curId] = array[randId];
    array[randId] = tmp;
  }
  return array;
}

const getForumData = function () {
  const pro1 = {
    commentType: "pro",
    logicalID: "p1",
    shortTitle: "So many attacks, only the government can act",
    content:
      "There have been lots of high-profile attacks recently, we read about them all the time on the news. It seems like each attack increases in scope and consequences. I can’t keep up with the constant stream of attacks and it seems that the entity in the best position to do something is the federal government. The government can pass laws to protect its citizens and prevent these kinds of attacks that are growing in frequency. ",
  };
  const pro2 = {
    commentType: "pro",
    logicalID: "p2",
    shortTitle: "Government can limit my data breach costs",
    content:
      "The personal costs of a data breach are huge. If someone gets a hold of your data and uses it to steal from you or other people, you are probably going to be on the hook for some portion of it. The costs and headaches of cyberattack go on and on and could last for years. The federal government can help keep our private information safe so we don’t face these kinds of costs. They should step in and help shield us from cyberattacks.",
  };
  const pro3 = {
    commentType: "pro",
    logicalID: "p3",
    shortTitle: "Government knows how to secure things",
    content:
      "The track record of data privacy protection by the government has been pretty good. Sure, there have been some large breaches. But it seems like most companies have at least one. When you think about the mountains of data the government needs to store, they must do a pretty good job because it is all where it is supposed to be. Building on this success, I think the federal government can do even more to safeguard data privacy and help individuals protect their data.",
  };
  const pro4 = {
    commentType: "pro",
    logicalID: "p4",
    shortTitle: "Only government gives protection and transparency",
    content:
      "Private companies that claim to provide data security take advantage of people. I never know what these companies are doing with the money they receive from people to protect their data. We need some transparency and private organizations can’t provide it. Only the federal government is in the position to credibly handle its citizens’ data. Everyone else wants to hide what they are doing or will take advantage of the data they are given.",
  };
  const con1 = {
    commentType: "con",
    logicalID: "c1",
    shortTitle: "We already know how to protect ourselves",
    content:
      "With a couple of basic actions, the threat of cyberattacks drops substantially. It isn’t too hard to choose a good password, install anti-virus software, avoid phishing messages, and not reuse passwords. People need to take care of themselves and shouldn’t have to rely on others, especially the federal government to come in and force some change. People can learn how to defend themselves and we can keep government out of our personal data.",
  };
  const con2 = {
    commentType: "con",
    logicalID: "c2",
    shortTitle: "Government shouldn’t have more of my data",
    content:
      "I want the government to know as little about me as possible. We already have to give a lot of data to the government with voting registration, taxes, employment information, real estate records, and a mountain of other things. If government takes a bigger role in protecting my data, I won’t have anything to keep private. The temptation for the federal government to abuse access to data like this is real. This is a comment that is con the issue. This is logical comment con2.",
  };
  const con3 = {
    commentType: "con",
    logicalID: "c3",
    shortTitle: "Working with the government would be hard",
    content:
      "Working with the government to improve personal data security would be a nightmare. It would take forever to get anything done and problems would take weeks to be resolved. There is so much ambiguity around personal information, so there would be tons of questions that regulators would have to argue over and courts would have to decide. I can’t even imagine what would happen to regular people who have a question – they would be buried in a mountain of bureaucracy.",
  };
  const con4 = {
    commentType: "con",
    logicalID: "c4",
    shortTitle: "Private companies can do it better",
    content:
      "The federal government should let private companies continue the battle against cyberattacks. Private companies are going to be much more nimble in responding to new threats and because there are lots of them, the forces of competition will incentivize the companies to perform well (or else they will fail). Government will be super slow and inefficient in getting anything done. All of our data will be stolen by new attacks before the government can even respond.",
  };

  const ratingPHH = {
    up: "83",
    down: "16",
    appreciate_up: "37",
    appreciate_down: "0",
  };
  const ratingCHH = {
    up: "84",
    down: "15",
    appreciate_up: "37",
    appreciate_down: "0",
  };
  const ratingPHL = {
    up: "82",
    down: "13",
    appreciate_up: "6",
    appreciate_down: "0",
  };
  const ratingCHL = {
    up: "81",
    down: "12",
    appreciate_up: "7",
    appreciate_down: "0",
  };
  const ratingPLH = {
    up: "14",
    down: "78",
    appreciate_up: "38",
    appreciate_down: "0",
  };
  const ratingCLH = {
    up: "15",
    down: "79",
    appreciate_up: "39",
    appreciate_down: "0",
  };
  const ratingPLL = {
    up: "12",
    down: "85",
    appreciate_up: "8",
    appreciate_down: "0",
  };
  const ratingCLL = {
    up: "13",
    down: "86",
    appreciate_up: "9",
    appreciate_down: "0",
  };

  // Usage of shuffle
  let pro_ratings = [ratingPHH, ratingPHL, ratingPLH, ratingPLL];
  pro_ratings = shuffleArray(pro_ratings);

  let con_ratings = [ratingCHH, ratingCHL, ratingCLH, ratingCLL];
  con_ratings = shuffleArray(con_ratings);

  //build out array of comments
  let comments = [
    { comment: pro1, rating: pro_ratings[0] },
    { comment: pro2, rating: pro_ratings[1] },
    { comment: pro3, rating: pro_ratings[2] },
    { comment: pro4, rating: pro_ratings[3] },
    { comment: con1, rating: con_ratings[0] },
    { comment: con2, rating: con_ratings[1] },
    { comment: con3, rating: con_ratings[2] },
    { comment: con4, rating: con_ratings[3] },
  ];
  comments = shuffleArray(comments);

  //now save the presentation order
  let pronum = 1;
  let connum = 1;
  for (let i = 0; i < comments.length; ++i) {
    if (comments[i].comment.commentType === "pro") {
      comments[i].title = "Pro #" + pronum;
      comments[i].presentationID = "p" + pronum;
      pronum += 1;
    } else {
      comments[i].title = "Con #" + connum;
      comments[i].presentationID = "c" + connum;
      connum += 1;
    }
  }

  return comments;
};

export const trainingComments = [
  {
    commentType: "pro",
    title: "Title Pro #1",
    shortTitle: "Gun control decreases violent crime",
    presentationID: "p1",
    logicalID: "p1",
    content: "We need more strict gun control laws.  Studies show that for each percentage point increase in gun ownership, the firearm homicide rate also increases.  Fewer guns available will lead to fewer gun crimes being committed, as demonstrated by gun crime rates in other countries (such as Japan) that have implemented more strict firearm controls.  Good people should not have to live in fear of being shot when they enter a public place, or even in their own homes.  By taking more guns out of people’s hands, firearm-related crimes will decrease.",
    up: 150,
    down: 26,
    appreciate_up: 24,
    isOpen: false,
    surveyJson: null,
  },
  {
    commentType: "con",
    title: "Title Con #1",
    shortTitle: "Gun control laws don't affect criminals",
    presentationID: "c1",
    logicalID: "c1",
    content: "More gun control laws are not the answer to decreasing gun-related crimes.  Guns will be sold on the black market among criminals regardless of any laws that exist.  Gun control laws will simply take guns away from law-abiding citizens, leaving them defenseless and emboldening criminals to use firearms in committing crimes.  In many cases, mass murderers have been deterred or stopped by citizens carrying lawfully concealed firearms.  We need to preserve the right for good people to keep and bear arms to defend themselves and their families.",
    up: 10,
    down: 142,
    appreciate_up: 155,
    isOpen: false,
    surveyJson: null,
  },
  {
    commentType: "pro",
    title: "Title Pro #2",
    shortTitle: "High capacity magazines lead to mass murder",
    presentationID: "p2",
    logicalID: "p2",
    content: "Prohibiting high-capacity magazines, ammunition feeding devices capable of holding more than 10-rounds, would reduce mass shooting deaths.  High-capacity magazines are often used in the violence that plagues our nation. Firearms equipped with high-capacity magazines account for an estimated 22 to 36% of crime guns. This threat to public safety has no place in our communities. Research shows that states with restrictions on magazine size experience mass shootings at less than half the rate of states without restrictions.",
    up: 10,
    down: 149,
    appreciate_up: 21,
    isOpen: false,
    surveyJson: null,
  },
  {
    commentType: "con",
    title: "Title Con #2",
    shortTitle: "Gun ownership is a constitutional right",
    presentationID: "c2",
    logicalID: "c2",
    content: "The intent of the second amendment is to guarantee the nation could never be overcome by any military power, foreign or domestic. If the military was not available… say a nuclear holocaust or a synchronized Pearl Harbor occurred… you would have to protect yourself. If you were limited to non-assault rifles, any invader with an AK-47 would have an easy day.  In addition, if the government restricts types of rifles or limits the amount of rounds, there’s no limit to what else they will restrict. The infringement of your constitutional rights won’t just stop at the type of arms you can bear.",
    up: 158,
    down: 20,
    appreciate_up: 147,
    isOpen: false,
    surveyJson: null,
  },
];

export default getForumData;
