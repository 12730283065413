import { useContext, useEffect, useState, useRef } from "react";
import { TrackingContext } from "../utils/trackingContext";
/*
Sample Tracking Data

{
    issueId: string;
    startTime: int;
    endTime: int;
    totalTime: int;
}
*/

export default function useTiming(presentationID, logicalID, orderID) {
  const { addTrackingData, updateTotals } = useContext(TrackingContext);
  const [startHover, setStartHover] = useState(0);

  const mouseOverHandler = (event) => {
    setStartHover(new Date());
  };

  const mouseOutHandler = (event) => {
    // console.log("onMouseLeave-"+event.target.id)
    let endTime = new Date();
    var timeDiff = endTime - startHover; //in ms

    // Create a new hover event for the tracking data
    let timeEvent = {
      presentationID,
      logicalID,
      orderID,
      // startTime: startHover,
      // endTime,
      timeDiff,
    };

    // Use the function from the context provider to add the tracking data
    addTrackingData(timeEvent);
    updateTotals(timeEvent);
  };

  return { mouseOverHandler, mouseOutHandler, startHover };
}
