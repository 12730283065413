import TrackingTotalsDisplay from "components/Tracking/TrackingTotalsDisplay";
import TrackingPathDisplay from "components/Tracking/TrackingPathDisplay";
import TrackingTransitionsDisplay from "components/Tracking/TrackingTransitionsDisplay";
import UploadTrackingData from "components/Tracking/UploadTrackingData";
import CommentGrid from "components/CommentGrid";

import NavBarAction from "components/Layout/NavBarAction";
import usePageTracker from "hooks/usePageTracker";
import { useState } from "react";

function ElaborationPage() {
  usePageTracker("elaborationPage");
  const [error, setError] = useState("");

  const errorHandler = () => {
    setError("Please read the posts by using the dropdown arrows");
  };

  return (
    <div className="App">
      <header>
        <title>Forum</title>
      </header>
      <main className="pt-4">
        <p className="mx-auto max-w-5xl rounded bg-amber-100 p-4 font-medium text-black">
          Please review the posts below about the proposition: The
          government should oversee the handling of online personal data by
          corporations and other entities to ensure the privacy of this
          information.
          <br/> You can choose how many to read and in
          which order. Click on the down arrow at the top of the post to see the
          full content. When you feel that you have adequately processed the
          information on this page, click Next. We’ll then ask you a few
          questions about the posts and your opinion on the issue.
        </p>
        <CommentGrid />
        <section className="mx-auto max-w-5xl space-y-4 pt-4">
          {error && <p className="text-red-700">{error}</p>}
          <NavBarAction
            actionButton={<UploadTrackingData errorHandler={errorHandler} />}
          />
        </section>
      </main>
      <aside className="py-4 text-center"></aside>
      {/* <TrackingTotalsDisplay className="mx-auto max-w-5xl pt-10" />
      <TrackingTransitionsDisplay className="mx-auto max-w-5xl pt-10" />
      <TrackingPathDisplay className="mx-auto max-w-5xl pt-10" /> */}
    </div>
  );
}

export default ElaborationPage;
