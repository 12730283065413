import NavBar from "components/Layout/NavBar";
import usePageTracker from "hooks/usePageTracker";

export default function InstructionPage() {
  usePageTracker("instructionPage");

  return (
    <>
      <div className="mx-auto h-full max-w-2xl py-4">
        <h1 className={`mb-1 text-3xl font-bold`}>Main Task Instructions</h1>
        <p className="mb-2">
          Your task is to review the posts shown on the next page. Later, we'll ask
          whether your opinion about government oversight of data privacy has
          changed (either stronger/weaker or reversed) or remained the same. 
          <br /><br />
          In reviewing the posts on the next page, you can decide how many posts to read and in which order.
          Remember, you can show the content of each post by clicking the down arrow at the top right of each box. 
          <br/><br/>
          You may choose to read all posts or only some of them, and you can re-read a
          post as many times as you’d like. You will then be asked some
          questions on subsequent screens about your opinion and what you
          read.
        </p>
        <NavBar nextPage="/elaboration" />
      </div>
    </>
  );
}
