import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import "styles/forms.css";
import PracticeCard from "components/PracticeCard";
import { ForumContext } from "utils/forumContext";
import usePageTracker from "hooks/usePageTracker";

const evaluationSurveyJson = {
  elements: [
    {
      type: "matrix",
      name: "gov-pre",
      title:
        "Indicate the degree to which you agree or disagree with each of the following statements:",

      columns: [
        {
          value: 1,
          text: "Strongly Disagree",
        },
        {
          value: 2,
          text: "Disagree",
        },
        {
          value: 3,
          text: "Neutral",
        },
        {
          value: 4,
          text: "Agree",
        },
        {
          value: 5,
          text: "Strongly Agree",
        },
      ],
      rows: [
        {
          value: "considered arguments",
          text: "I carefully considered the arguments raised by this post.",
        },
        {
          value: "influence",
          text: "This post influenced how I view the issue.",
        },
        {
          value: "agreed with position",
          text: "I agreed with the position expressed in this post.",
        },
        {
          value: "appreciated perspective",
          text: "I appreciated the perspective/reasoning of the post whether or not I agreed with its position.",
        },
      ],
    },
  ],
};

export default function Evaluation() {
  const [commentData, setCommentData] = useState([]);
  const [displayCard, setDisplayCard] = useState(0);
  const { comments } = useContext(ForumContext);
  const { participantID } = useContext(ForumContext);

  usePageTracker("evaluationPage");

  // If the comment data changes, updat the local comment state, setting them all to be closed
  useEffect(() => {
    setCommentData(comments.map((comment) => ({ ...comment, isOpen: false })));
  }, [comments]);

  if (commentData.length === 0) {
    return (
      <section>
        <h1>Loading...</h1>
      </section>
    );
  }

  const changeDisplayCard = (incrementer) => {
    setDisplayCard((prev) => {
      if (prev + incrementer === comments.length) {
        // redirect to qualtrics survey
        window.location.href = `https://byu.az1.qualtrics.com/jfe/form/SV_0OFOmqoSrfq7MSa?ExperimentParticipantID=${participantID}`;
      } else {
        setDisplayCard(prev + incrementer);
        console.log(window.location.href);
      }
    });
  };

  return (
    <>
      <div className="mx-auto h-full max-w-5xl p-4">
        <h1 className="mb-1 text-3xl font-bold">Evaluation</h1>
        <p className="mx-auto max-w-5xl rounded bg-amber-100 p-4 font-medium text-black">
          Now consider the posts that you read. For each post that is presented, answer the
          following questions:
        </p>
        <br />
        {comments.map((comment, index) =>
          displayCard === index ? (
            <PracticeCard
              key={index}
              commentType={comment.commentType}
              title={comment.title}
              shortTitle={comment.shortTitle}
              presentationID={comment.presentationID}
              logicalID={comment.logicalID}
              content={comment.content}
              up={comment.up}
              down={comment.down}
              appreciate_up={comment.appreciate_up}
              isOpen={comment.isOpen}
              changeDisplayCard={changeDisplayCard}
              surveyJson={evaluationSurveyJson}
              page="evaluation"
            />
          ) : null
        )}
      </div>
    </>
  );
}
