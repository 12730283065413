import NavBar from "components/Layout/NavBar";
import usePageTracker from "hooks/usePageTracker";

export default function IntroPage() {
  usePageTracker("introPage");

  return (
    <>
      <div className="mx-auto h-full max-w-2xl py-4">
        <h1 className={`mb-1 text-3xl font-bold`}>Intro</h1>
        <p className="mb-2">
          Online forums are places where people can post questions and express
          opinions on a wide variety of issues. This experiment is about how
          people use online forums to learn about social issues. In this
          experiment, you will be asked to:
        </p>
        <ol className="ml-2 mb-2 space-y-2">
          <li>
            1. Consider and state your opinion about the issue of government
            oversight of online data privacy.
          </li>
          <li>
            2. Read online forum posts related to this issue. (You will be able
            to choose which forum posts to read.)
          </li>
          <li>
            3. Re-evaluate your opinion about the issue and determine whether
            your perspective has changed.
          </li>
        </ol>
        <NavBar nextPage="/consent" />
      </div>
    </>
  );
}
