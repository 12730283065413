import { useContext } from "react";
import { TrackingContext } from "utils/trackingContext";

function TrackingTransitionsDisplay() {
  const { transitions } = useContext(TrackingContext);

  return (
    <div className="grid grid-cols-4">
      <div />
      <div className="col-span-2 grid grid-cols-5 ">
        {Object.keys(transitions)
          .sort()
          .map(function (key, index) {
            return (
              <div key={index}>
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-blue-400 px-3 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400">
                  {key}
                </span>
                <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-blue-100 px-3 text-sm text-gray-900 dark:border-gray-600 dark:bg-gray-600 dark:text-gray-400">
                  {(transitions[key] / transitions["total"]).toLocaleString(
                    undefined,
                    { style: "percent", minimumFractionDigits: 0 }
                  )}
                </span>
              </div>
            );
          })}
      </div>
      <div />
    </div>
  );
}

export default TrackingTransitionsDisplay;
