import { useContext, useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { DragDropContext } from "react-beautiful-dnd";
import { generateID, reorder } from "utils/helpers";
import { ForumContext } from "utils/forumContext";
import DropdownComment from "components/Comment";
import NavBarAction from "components/Layout/NavBarAction";
import UploadRankButton from "components/RankComponents/UploadRankButton";

const RankList = ({rankType, rankInstructions, route}) => {
  const [forumData, setForumData] = useState(null);
  const [openIndex, setOpenIndex] = useState(null);
  const { comments } = useContext(ForumContext);

  useEffect(() => {
    const commentsData = comments.map((item) => ({
      ...item,
      id: generateID(),
    }));

    setForumData(commentsData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newList = reorder(
      forumData,
      result.source.index,
      result.destination.index
    );

    setForumData(newList);
  };

  const handleCommentClick = (index) => {
    if (index === setOpenIndex) {
      openIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  if (!forumData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <section className="mx-auto max-w-4xl py-4">
        <h1 className="mb-1 text-3xl font-bold">{rankType} </h1>
        <p className="mx-auto max-w-5xl rounded bg-amber-100 p-4 font-medium text-black">
        {rankInstructions}
        </p>
        <br />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {forumData.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <DropdownComment
                          isOpen={index === openIndex}
                          setOpen={() => handleCommentClick(index)}
                          key={item.logicalID}
                          commentType={item.commentType}
                          title={item.title}
                          shortTitle={item.shortTitle}
                          presentationID={item.presentationID}
                          logicalID={item.logicalID}
                          orderID={item.orderID}
                          content={item.content}
                          up={item.up}
                          down={item.down}
                          appreciate_up={item.appreciate_up}
                        />
                        <br />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </section>
      <section className="mx-auto max-w-5xl space-y-4 pt-4">
      <NavBarAction
        actionButton={
          <UploadRankButton
            data={forumData.map((data, index) => ({
              logicalID: data.logicalID,
              presentationID: data.presentationID,
              orderID: data.orderID,
              rankNum: index + 1,
            }))}
            dbKey="rankData"
            route={route}
          />
        }
      />
      </section>
    </>
  );
};

export default RankList;
